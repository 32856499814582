.lessonoverview-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.lessonregen-container {
  border: 2px solid #7d5ff5;
  height: 30px;
  width: 30px;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
  border-radius: 10%;
  margin-top: 1rem;
}

.lessonregen-container:hover {
  background-color: #7d5ff5;
  cursor: pointer;
}

.lessonregen-container:hover .ai-generate-icon {
  color: white !important;
}

.ai-generate-icon {
  transform: translate(0, -0.8px);
}

.lessonregen-container:hover .ringloader {
  color: white !important;
}

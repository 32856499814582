.password-hint {
    padding: 0;
    margin: 12px 0 0;
    font-family: DMSansRegular, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: var(--primary-violet-500);
    text-align: start;
}

.password-hint.error {
    color: var(--red-500);
}

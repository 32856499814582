.banner {
  width: 100%;
  text-align: center;
  background-color: #f4b400;
  color: black;
  padding: 10px 0;
  font-weight: bold;
  box-shadow: 0px 0px 10px lightgray;
  margin-bottom: 20px;
}

.cta {
  max-width: 70%;
  margin: auto;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 7px;
}

.cta h3 {
  font-size: 1.3rem;
}

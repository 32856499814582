.btn-primary {
  background-color: white;
  margin-bottom: 1.5rem;
  border: 1px dashed #1e90ff;
  color: #1e90ff;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  border-radius: 1.5rem;
}

.btn-primary .buttonloadingelements {
  background-color: #1e90ff;
  color: white;
}

.btn-primary:hover {
  background-color: #1e90ff;
  color: white;
}

.btn-primary:active {
  background-color: #1976d3;
  color: white;
}

.buttonelements {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonloadingelements {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e90ff;
  color: white;
}

.buttonicon {
  margin-right: 0.8rem;
  font-weight: 400;
}

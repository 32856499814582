/* Task: Center the AddNewResource Btn below the file manager and ensure it has delightful spacing */

.FilesListViewContainer {
  font-family: 'Arial', sans-serif;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
}

.headerRow,
.fileRow {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
}

.headerRow {
  background-color: #f5f5f5;
  font-weight: bold;
}

.headerColumn,
.fileColumn {
  padding: 8px 16px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Optional hover effect for file rows */
.fileRow:hover {
  background-color: #e9f4fd;
  cursor: pointer;
}

/* Adjustments for the "..." column to be smaller than the others */
.fileRow .fileColumn:last-child {
  flex: 0.2;
  text-align: center;
  color: #007bff; /* Change to any color you prefer for the ellipsis */
}

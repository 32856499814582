.titleAndVideo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Create a modern looking button that will act as the button that triggers a video modal popup. The button should have a blue outline and text and look very good for a modern day SaaS. */
.modalbtn {
    background-color: #fff;
    color: #7d5ff5;
    border: 2px solid #7d5ff5;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.modalbtn:hover {
    background-color: #7d5ff5;
    color: #fff;
}

.downloadInfo {
    padding-left: 20%;
    padding-right: 20%;
    color: rgb(98, 98, 98);
    font-size: 1.3em;
    /*font-family: inter;*/
    font-weight: 600;
    text-align: center;
}

.downloadInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;
}

.downloadicon {
    margin-bottom: 2rem;
    width: 4.5rem;
    height: 4.5rem;
}

.downloadicon {
    color: #7d5ff5;
}

.pdfDownloadLink {
    margin: 5% !important;
    text-align: center;
}

.pdfDownloadBtn {
    font-size: 1em;
    /*font-family: inter;*/
    font-weight: 600;
}

/*
  
  BEGIN STYLING FOR PDF CONTAINER

*/

.wrapper {
    height: 100vh;
    width: 100vw;
    background-color: var(--white);
    display: flex;
}

.formWrapper {
    margin: auto;
    width: 100%;
    max-width: 360px;
    text-align: center;
    padding-bottom: 100px;

    .logo {
        margin-bottom: 24px;
    }

    .google-icon {
        margin-left: 12px;
    }
}

.seperatorText {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--grey-500);
    line-height: 0.1em;
    margin: 10px 0;
}

.seperatorText span {
    background: var(--white);
    padding: 0 12px;
    font-family: DMSansRegular, sans-serif;
    color: var(--grey-500);
}

.signinForm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    width: 100%;
    max-width: 360px;
}

@media (max-width: 1280px) {
    .formWrapper .logo {
        margin-bottom: 12px;
    }
}

@media (max-width: 550px) {
    .formWrapper {
        margin-top: 48px;
        padding: 0 18px;
    }

    .formWrapper .signinForm .MuiFormLabel-root {
        width: 100%;
    }

    .formWrapper .logo {
        margin-bottom: 0;
    }
}

.MuiTypography-root.link-text {
    margin-top: 12px;
}

.banner {
    background-color: #f0c14b;
    color: #111;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 1.2em;
    border-radius: 5px;
    width: 100%;
  }
  
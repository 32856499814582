.creations {
  padding: 2rem;
}

.pageheader {
  display: flex;
  justify-content: space-between;
}

.pageheader a {
  color: black !important;
}

.plusicon {
  width: 0.8em;
  height: 0.8em;
  color: #7d5ff5;
}

.request {
  border: 2.5px solid #7d5ff5;
  border-radius: 20px;
  padding-bottom: 0.2em;
  padding-top: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-size: 20px;
}

.request:hover {
  box-shadow: 0 0 10px #7d5ff5;
}

/* Individual File Cards */

.files {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.3rem;
}

@media (min-width: 640px) {
  .files {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media (min-width: 992px) {
  .files {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media (min-width: 1100px) {
  .files {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
}
@media (min-width: 1250px) {
  .files {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }
}

.creations-header {
  margin-top: 1.5rem;
}

/* .bodyText {
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
} */

.completion {
  padding: 1rem;
  border-radius: 7px;
  background-color: white;
  border: #7d5ff5 2px solid;
}

.completion:hover {
  box-shadow: 0 0 8px #614abe;
}

.video-title {
    font-size: 0.9rem;
    font-weight: 600;
    color: #2e2e2e;
    margin-bottom: 0.5rem;
    font-family: DMSansBold, sans-serif;
    line-height: normal;
    text-align: center;
    padding: 0.8rem 0.8rem 0 0.8rem;
    background-color: #f0f4f8;
    transform: translateY(-16%);
}

.video-container {
    background-color: #f0f4f8;
    border-radius: 0 0 8px 8px;
    border: 1px solid #e0e0e0;
    margin-bottom: 1rem;
}

.video-container:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
}

.buttoncontainer-yt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
}

.buttoncontainer-yt button {
    background-color: #7d5ff5;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0 auto;
    padding: 0.3rem 0.5rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
    font-family: DMSansBold, sans-serif;
}

.buttoncontainer-yt button:hover {
    background-color: #3f2a93;
    border: 1px solid #3f2a93;
    color: #fff;
}

.video {
    min-width: 200px;
}

@media (max-width: 1200px) {
    .video {
        height: 350px;
    }

    .video-container {
        margin-bottom: 5rem;
    }
}

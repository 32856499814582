.wrapper {
    height: 100vh;
    width: 100vw;
    background-color: var(--white);
    display: flex;
}

.formWrapper {
    margin: auto;
    width: 100%;
    max-width: 360px;
    text-align: center;
    padding-bottom: 100px;

    .logo {
        margin-bottom: 24px;
    }
}


.personalInfoForm {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    width: 100%;
    max-width: 360px;
}

.personalInfoForm svg .MuiSvgIcon-root {
    font-size: 12px;
    color: var(--grey-700);
}

@media (max-width: 1280px) {
    .formWrapper .logo {
        margin-bottom: 12px;
    }
}

@media (max-width: 550px) {
    .formWrapper {
        margin-top: 48px;
        padding: 0 18px;
    }

    .formWrapper .personalInfoForm .MuiFormLabel-root {
        width: 100%;
    }

    .formWrapper .logo {
        margin-bottom: 0;
    }
}



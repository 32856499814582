.socialIconDiv {
    height: 3rem;
    width: 100%;
    background-color: #1e43ff;
    border-radius: 0.5rem;
    border: none;
}

.signupText {
    color: white;
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-family: DMSansBold, sans-serif;
    font-size: 18px;
}

.socialIconDiv:hover {
    background-color: #516eff;
}

.socialIconDiv:hover {
    cursor: pointer;
}

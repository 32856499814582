.emoji {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 10px;
}

.drawerbox {
    width: 75vw;
    height: 100%;
    background: #f8fafc;
}

@media (max-width: 800px) {
    .drawerbox {
        width: 100vw;
    }
}

/* day-card is a card with two columns side by side. On the left side are accordian toggles to show information about the day's lesson plan and on the right side is a lesson resources tab with options to view and generate files */

.day-card {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    column-gap: 1rem;
    padding: 10px;
}

@media (max-width: 1200px) {
    .day-card {
        grid-template-columns: 1fr;
    }
}

.day-details,
.day-resources {
    border: 1px solid rgb(214, 214, 214);
    border-radius: 7px;
    padding: 1rem;
    background-color: white;
}

.textarea {
    border: none !important;
    padding: none !important;
    margin: none !important;
}

.lessonoverview-textarea {
    width: 100%;
    height: 20vh;
    border: none;
    resize: none;
    overflow: auto;
    outline: 1px solid rgb(227, 227, 227);
    padding: 0.7rem;
}

.css-15v22id-MuiAccordionDetails-root {
    padding: 0px !important;
}

/* Currently, all the dayCards are equal in height, but the CTA at the bottom of the card is at a different height for each card because of the content length above the CTA button. How can I fix this?   

Answer in code:*/

.dayCard {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
}

.css-46bh2p-MuiCardContent-root:last-child {
    height: 100%;
}

/* Align all of the elements inside of this div in the center of the div */
.studentobjectives-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

/* Align all of the buttons inside the studentobjectives-container in the middle of the div  */
.studentobjectives-container button {
    margin: 2rem auto !important;
}

.objectives-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2rem;
}

.objectivestext-container {
    padding: 0rem 0.8rem 0rem 0.8rem;
}

.button-icon {
    margin-right: 0.5rem;
    transform: translateY(2px);
}

.lessonOverview-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.regenbutton {
    position: absolute;
    top: 5.5rem;
    right: 0.8rem;
}

.tool-wrapper {
    position: relative;
}

.tool-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: grayscale(1) blur(6px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #b7b7b7;
}

.tool-disabled .upgrade-link {
    color: #7860ED;
    /*font-family: Inter, sans-serif;*/
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    display: none;
}

.tool-disabled:hover .upgrade-link {
    display: block;
}

.disabled .MuiTypography-h5.toolTitle,
.disabled .MuiTypography-body2 {
    color: #b7b7b7;
}

.tool-wrapper .MuiTypography-root {
    font-family: DMSansRegular, sans-serif;
}